import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "./components/layouts/MainLayout";
import Home from "./components/pages/home/Home";
import Shop from "./components/pages/shop/Shop";
import ProductView from "./components/pages/product-view/ProductView";
import Cart from "./components/pages/cart/Cart";
import ConfirmAtionPage from "./components/pages/order-pages/ConfirmAtionPage";
import CancelPage from "./components/pages/order-pages/CancelPage";
import Legal from "./components/pages/legal/Legal";
import Contact from "./components/pages/contact/Contact";
import CookieBanner from "./components/cookie-banner/CookieBanner";
import NotFound from "./components/pages/404/NotFound";
import AboutUs from "./components/pages/aboutus/AboutUs";

function App() {
  /*const analyticsAllowed = useSelector((state) => state.analyticsAllowed);

  useEffect(() => {
    if (analyticsAllowed) {
      // analytics.app.automaticDataCollectionEnabled = true;
    }
  }, [analyticsAllowed]);*/

  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/products/:id" element={<ProductView />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/cart/confirmation/:id" element={<ConfirmAtionPage />} />
          <Route path="/cart/cancel/" element={<CancelPage />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <CookieBanner />
      </MainLayout>
    </Router>
  );
}

export default App;
