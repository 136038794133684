import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    value: [],
  },
  reducers: {
    addProduct: (state, action) => {
      const itemToAdd = action.payload.product;
      const itemExists = state.value.find(
        (item) => item.product.id === itemToAdd.id
      );

      if (itemExists) {
        itemExists.qty += action.payload.qty;
      } else {
        state.value.push({
          product: itemToAdd,
          qty: action.payload.qty,
        });
      }
      console.log(state);
    },
    removeProduct: (state, action) => {
      const newCart = state.value.filter(
        (item) => item.product.id !== action.payload.product.id
      );

      state.value = newCart;
    },
    incrementProduct: (state, action) => {
      const item = state.value.find(
        (item) => item.product.id === action.payload.product.id
      );
      item.qty++;
    },
    decrementProduct: (state, action) => {
      const item = state.value.find(
        (item) => item.product.id === action.payload.product.id
      );

      if (item.qty > 1) {
        item.qty--;
      } else {
        item.qty = 1;
      }
    },
  },
});

export const { incrementProduct, decrementProduct, addProduct, removeProduct } =
  cartSlice.actions;

export const  cartReducer = cartSlice.reducer;
