import React, { useEffect, useRef } from "react";
import style from "./style.module.scss";
import StartRating from "../start-rating/StartRating";
import { FaCartPlus } from "react-icons/fa6";
import { motion, useAnimation, useInView } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../redux/cartSlice";
import Button from "../button/Button";

const ProductCard = ({ delay, product }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const addToCart = () => {
    dispatch(addProduct({ product: product, qty: 1 }));
  };

  const onCardClick = () => {
    window.location.href = "/products/" + product.id;
  };

  return (
    <div ref={ref}>
      <motion.div
        style={{ width: "fit-content", height: "fit-content" }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: delay }}
      >
        <div onClick={onCardClick} className={style.product_card}>
          <div className={style.product_card_img}>
            <img alt="" src={product.thumbnail} />
          </div>

          <div className={style.product_card_title}>
            <h1 className={style.product_card_title_text}>{product.name}</h1>
          </div>

          <p
            dangerouslySetInnerHTML={{
              __html: product.description
                .split(" ")
                .map((v) => v + "<wbr/>")
                .join(" "),
            }}
          />
          <StartRating rating={4} />

          <div className={style.product_card_price}>
            <h3>
              Pieces: {product.pieces}
            </h3>
            <h2>{(product.price / 100).toFixed(2)}€</h2>
          </div>
          {product.comingSoon ? (
            <h1
              style={{ width: "100%", textAlign: "center" }}
              className={style.product_card_title_text}
            >
              Coming soon!
            </h1>
          ) : (
            <Button
              clickable={true}
              onClick={() => addToCart()}
              Icon={FaCartPlus}
              text={"Add to cart"}
            />
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ProductCard;
