import React from "react";
import style from "./style.module.scss";
import { IoBagCheckOutline } from "react-icons/io5";
import { FiMinus, FiPlus } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import {
  decrementProduct,
  incrementProduct,
  removeProduct,
} from "../../../redux/cartSlice";
import Button from "../../button/Button";

const CartItem = ({ item, del, inc, dec }) => {
  return (
    <div className={style.mobile_cart_item}>
      <div className={style.mobile_cart_item_img}>
        <RxCross2
          onClick={() => del(item)}
          className={style.mobile_cart_item_img_icon}
        />

        <a href={"/products/" + item.product.id}>
          <img alt="productImage" src={item.product.thumbnail} />
        </a>
      </div>

      <ul className={style.mobile_cart_item_info}>
        <li>
          <h1>{item.product.name}</h1>
        </li>

        <li>
          <h2>
            <small>#{item.product.id}</small>
          </h2>
        </li>

        <li>
          <h2>
            <small>
              Pieces: {item.pieces} - Stock:
              {item.product.preorder ? " Preorder" : item.product.stock}
            </small>
          </h2>
        </li>

        <div className={style.mobile_cart_item_info_split}>
          <h3>{(item.product.price / 100).toFixed(2)}€</h3>

          <div className={style.cart_item_qty}>
            <button onClick={() => dec(item)}>
              <FiMinus color="#333" size={"20px"} />
            </button>
            <p style={{ width: "25px", textAlign: "center" }}>{item.qty}</p>
            <button onClick={() => inc(item)}>
              <FiPlus color="#333" size={"20px"} />
            </button>
          </div>
        </div>
      </ul>
    </div>
  );
};

export const MobileCart = ({
  cart,
  checkout,
  amount,
  dispatcher,
  setAgree,
  agree,
}) => {
  return (
    <main className={style.mobile_cart_container}>
      <section className={style.mobile_cart_container_list}>
        {cart.length === 0 ? (
          <h1>
            Your cart is empty 🙁<br/> Please checkout some{" "}
            <a href="/shop">products</a>
          </h1>
        ) : (
          cart.map((item) => (
            <CartItem
              inc={(i) => {
                dispatcher(incrementProduct(i));
              }}
              dec={(i) => {
                dispatcher(decrementProduct(i));
              }}
              del={(i) => {
                dispatcher(removeProduct(i));
              }}
              item={item}
            />
          ))
        )}
      </section>

      <section className={style.mobile_cart_container_checkout}>
        <div className={style.mobile_cart_container_checkout_total}>
          <h5>CART TOTAL:</h5>
          <h5>
            {amount}€ <small>incl. vat</small>
          </h5>
        </div>

        <p>Shipping & taxes calculated at checkout</p>

        <div className={style.mobile_cart_container_checkout_agree}>
          <input
            checked={agree}
            onChange={() => {
              setAgree(!agree);
            }}
            type="checkbox"
          />

          <label>
            I agree to the <a href="/legal">Terms & Conditions</a>
          </label>
        </div>
        <div className={style.mobile_cart_container_checkout_button}>
          <Button
            text={"Checkout"}
            onClick={checkout}
            Icon={IoBagCheckOutline}
            clickable={agree}
          />
        </div>
      </section>
    </main>
  );
};
