import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import ProductCard from "../../product-card/ProductCard";
import { getAllProducts } from "../../../firebase/store_functions";

const Shop = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts(setProducts, products);
  }, []);

  return (
    <div className={style.shop_container}>
      <div className={style.shop_container_topbanner}>
        <img
          alt="banner"
          src={require("../../../images/collection_image.jpg")}
        />
      </div>

      <h2 className={style.shop_container_subtitle}>-The best of the best-</h2>
      <h1 className={style.shop_container_title}>Explore our collections</h1>
      <section className={style.shop_container_products}>
        <div className={style.shop_container_products_list}>
          {products.map((v, i) => (
            <ProductCard product={v} delay={(i % 3) * 0.25} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Shop;
