import React from "react";
import style from "./style.module.scss";
import global_style from "../../../styles/global.module.scss";

const AboutUs = () => {
  return (
    <div className={style.aboutus_container}>
      <div className={style.aboutus_container_top}>
        <h1>Who we are</h1>
      </div>

      <section className={style.aboutus_container_bottom}>
        <div className={style.aboutus_container_bottom_section}>
          
          <img alt="" src={require("../../../images/about_image.jpg")} />
          
          <div
            className={[
              global_style.center_children,
              style.aboutus_container_bottom_section_text,
            ].join(" ")}
          >
            <h1>Based in Sweden</h1>
            <p>
                Our company is located in beautiful Sweden, here we design, make and ship our products.
            </p>
            <p>
             AdultBricks strive to innovate on unique ideas and sell sets that have some more humour in them.
            </p>
          </div>
        </div>

        <div className={style.aboutus_container_bottom_section}>
          <div
            className={[
              global_style.center_children,
              style.aboutus_container_bottom_section_text,
            ].join(" ")}
          >
            <h1>Designing with passion</h1>
            <p>
              Every brick in each set is carefully placed based on thoughts and
              ideas.
            </p>
            <p>
              When we make a product, the parable and enjoyment of building is the
              priority.
            </p>
          </div>

          <img alt="" src={require("../../../images/designing.png")} />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
