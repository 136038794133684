import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const ReavelText = ({ children, color, delay = 0}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      animControls.start("visible");
    }
  }, [isInView]);

  return (
    <div
      ref={ref}
      style={{ position: "relative", width: "fit-content", overflow: "hidden" }}
    >
      {children}
      <motion.div
        variants={{ hidden: { left: 0 }, visible: { left: "100%" } }}
        initial={"hidden"}
        animate={animControls}
        transition={{ delay: delay, duration: 0.3, ease: "easeIn" }}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          backgroundColor: color,
          zIndex: 10,
        }}
      />
    </div>
  );
};

export default ReavelText;
