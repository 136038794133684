import React, { useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import style from "./style.module.scss";

const Button = ({ Icon, text, onClick, clickable }) => {
  const [animating, setAnimating] = useState(false);

  const clicked = (event) => {
    event.stopPropagation();

    if (!animating) {
      
        onClick();

      if (clickable)
        // start animation
        setAnimating(true);
    }
  };

  return (
    <button
      onClick={(event) => clicked(event)}
      className={style.button_cart_effect}
    >
      <motion.div
        style={{
          width: "fit-content",
          height: "fit-content",
          position: "absolute",
          left: "10%",
          top: "25%",
        }}
        animate={{
          left: !animating ? "10%" : "110%",
        }}
        onAnimationComplete={() => {
          setAnimating(false);
        }}
        onAnimation
        transition={{ duration: animating ? 0.9 : 0 }}
      >
        <Icon className={style.button_cart_effect_icon} />
      </motion.div>

      <motion.div
        animate={{
          opacity: !animating ? 1 : 0,
        }}
        transition={{ duration: animating ? 0.9 : 0 }}
      >
        {text}
      </motion.div>
    </button>
  );
};

export default Button;
