import React from "react";
import style from "./style.module.scss";
import { FaStar, FaRegStar } from "react-icons/fa";

const StartRating = ({ rating,reviews }) => {
  return (
    <div className={style.rating_container}>
      {Array.from({ length: rating }, () => (
        <FaStar className={style.rating_container_icon} />
      ))}
      {Array.from({ length: 5 - rating }, () => (
        <FaRegStar className={style.rating_container_icon} />
      ))}

      {
        reviews!== undefined? <p>({reviews})</p>:null
      }
    </div>
  );
};

export default StartRating;
