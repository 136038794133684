import React from "react";
import style from "./style.module.scss";
import global_style from "../../styles/global.module.scss";

import { AiFillInstagram, AiFillTikTok } from "react-icons/ai";

const Footer = () => {
  return (
    <footer
      className={[global_style.max_size, global_style.center_children].join(
        " "
      )}
    >
      <ul className={style.footer_list}>
        <li>
          <h2>Company</h2>
          <span className={style.footer_list_underline} />
          <a href="/about">About Us</a>
          <a href="/about">Our services</a>
          <a href="/legal">Privacy policy</a>
        </li>

        <li>
          <h2>Get Help</h2>
          <span className={style.footer_list_underline} />
          <a href="/contact">FAQ</a>
          <a href="/contact">Shipping</a>
          <a href="/contact">Returns</a>
          <a href="/contact">Contact</a>
        </li>

        <li>
          <h2>Online Shop</h2>
          <span className={style.footer_list_underline} />
          <a href="/shop">Shop</a>
        </li>

        <li>
          <h2>Follow Us</h2>
          <span className={style.footer_list_underline} />

          <div className={style.footer_list_social}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/adultbricks_store?igsh=aWE5cWcyOGpvc242&utm_source=qr"
            >
              <AiFillInstagram className={style.footer_list_social_icon} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@adultbricks?_t=8lW8h5lBsu1&_r=1"
            >
              <AiFillTikTok className={style.footer_list_social_icon} />
            </a>
          </div>
        </li>
      </ul>

      <p>&copy; 2024 AdultBricks. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
