import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import global_style from "../../../styles/global.module.scss";
import { MdDesignServices, MdBuild } from "react-icons/md";
import { FaPersonRays, FaMoneyBillWave } from "react-icons/fa6";
import ReavelText from "../../special-effects/ReavelText";
import ProductCard from "../../product-card/ProductCard";
import { getFeatured } from "../../../firebase/store_functions";

const AboutUsCard = ({ Icon, text, title }) => {
  return (
    <div className={style.about_card}>
      <Icon className={style.about_card_icon} />
      <h1>{title}</h1>
      <h2>{text}</h2>
    </div>
  );
};

const Home = () => {
  const [featured_product, setFeatured_product] = useState();

  useEffect(() => {
    getFeatured(setFeatured_product, featured_product, 1);
  }, []);

  return (
    <div
      className={[
        style.home_container,
        global_style.center_children,
        global_style.vertical_list,
      ].join(" ")}
    >
      <section className={style.home_container_top}>
        <div className={style.home_container_top_left}>
          <ReavelText color={"#3F2305"}>
            <h1 className={style.home_container_title}>Unbox. Build.</h1>
          </ReavelText>
          <ReavelText delay={0.3} color={"#3F2305"}>
            <h1 className={style.home_container_title}>Enjoy.</h1>
          </ReavelText>
          <h2 className={style.home_container_subTitle}>
            The choice for people who want something special & unique
          </h2>

          <a href="/shop">Find the one</a>
        </div>

        <img
          alt=""
          src={require("../../../images/IMG_1115.jpg")}
        />
      </section>

      <div className={style.home_container_bottom}>
        <h1 className={style.home_container_bottom_title}>
          Taking steps like never before
        </h1>

        <section className={style.home_container_bottom_about}>
          <AboutUsCard
            text={
              "We are a dedicated team that loves designing things not seen before"
            }
            title={"Design"}
            Icon={MdDesignServices}
          />
          <AboutUsCard
            title={"Innovate"}
            text={
              "Building the sets no-one dared to build, making new and interesting ones"
            }
            Icon={MdBuild}
          />
          <AboutUsCard
            title={"You"}
            text={
              "You the customer is our priority! Our experience together is going to be silk-smooth"
            }
            Icon={FaPersonRays}
          />
          <AboutUsCard
            title={"Good pricing"}
            text={
              "To make sure everyone can afford, these sets are offered at a very low price tag"
            }
            Icon={FaMoneyBillWave}
          />
        </section>

        <section className={style.home_container_bottom_split}>
          <div
            className={[
              global_style.center_children,
              global_style.vertical_list,
            ].join(" ")}
          >
            <h2
              style={{ marginBottom: "20px" }}
              className={style.home_container_title}
            >
              Top seller
            </h2>
            {featured_product !== undefined ? (
              <ProductCard product={featured_product[0]} />
            ) : null}
          </div>

          <div className={style.home_container_bottom_split_right}>
            <ReavelText delay={0.3} color={"#3F2305"}>
              <h1 className={style.home_container_title}>What we sell</h1>
            </ReavelText>

            <h2 className={style.home_container_subTitle}>
              We sell our custom designs to a worldwide customer base
            </h2>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
