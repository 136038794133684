import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import global_style from "../../../styles/global.module.scss";
import { IoBagCheckOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementProduct,
  incrementProduct,
  removeProduct,
} from "../../../redux/cartSlice";
import { RxCross2 } from "react-icons/rx";
import Button from "../../button/Button";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/Firebase";
import { MobileCart } from "./MobileCart";

const CartItem = ({ item, del, inc, dec }) => {
  return (
    <ul className={style.cart_item}>
      <li>
        <div className={style.cart_item_product}>
          <RxCross2
            onClick={() => {
              del(item);
            }}
            className={style.cart_item_product_cross}
          />
          <img alt="product" src={item.product.thumbnail} />
          <h1>{item.product.name}</h1>
          <p>#{item.product.id}</p>
          <p>
            Stock: {item.product.preorder ? "preorder" : item.product.stock}
          </p>
        </div>
      </li>

      <li>
        <h2>{(item.product.price / 100).toFixed(2)}€</h2>
      </li>

      <li>
        <div className={style.cart_item_qty}>
          <button onClick={() => dec(item)}>-</button>
          <p>{item.qty}</p>
          <button onClick={() => inc(item)}>+</button>
        </div>
      </li>

      <li>
        <h2>{((item.product.price * item.qty) / 100).toFixed(2)}€</h2>
      </li>
    </ul>
  );
};

const Cart = () => {
  const cart = useSelector((state) => state.value);
  const dispatch = useDispatch();

  const [amount, setAmount] = useState();
  const [agree, setAgree] = useState();

  function calcTotal() {
    var amount = 0;

    cart.forEach((item) => {
      amount += item.product.price * item.qty;
    });

    setAmount((amount / 100).toFixed(2));
  }

  useEffect(() => {
    calcTotal();
  }, [cart]);

  const checkout = () => {
    if (!agree) {
      alert("You must agree to the terms & conditions to make a order.");
      return;
    }

    const SessionCall = httpsCallable(functions, "createOrder");

    SessionCall({ order: cart })
      .then((res) => {
        window.location.href = res.data.redirect_url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (window.innerWidth > 900)
    return (
      <div
        className={[global_style.max_size, global_style.center_children].join(
          " "
        )}
      >
        <div className={style.cart_container}>
          <div className={style.cart_container_items}>
            <div className={style.cart_container_items_topbar}>
              <h1>PRODUCT</h1>
              <h1>PRICE</h1>
              <h1>QTY</h1>
              <h1>TOTAL</h1>
            </div>

            <div className={style.cart_container_items_list}>
              {cart.length > 0 ? (
                cart.map((e) => (
                  <CartItem
                    inc={(i) => {
                      dispatch(incrementProduct(i));
                    }}
                    dec={(i) => {
                      dispatch(decrementProduct(i));
                    }}
                    del={(i) => {
                      dispatch(removeProduct(i));
                    }}
                    item={e}
                  />
                ))
              ) : (
                <h2>
                  Your cart is empty 🙁
                  <br /> Please checkout some <a href="/shop">products</a>
                </h2>
              )}
            </div>
          </div>

          <div className={style.cart_container_info}>
            <div className={style.cart_container_info_amount}>
              <h2>CART TOTAL: </h2>
              <h1>{amount}€</h1>
            </div>

            <p>Shipping cost & fees are applied at checkout</p>

            <div className={style.cart_container_info_terms}>
              <input
                type="checkbox"
                value={agree}
                onChange={() => setAgree(!agree)}
              />
              <p>
                I agree to <a href="/legal">Terms of service</a>
              </p>
            </div>

            <Button
              onClick={checkout}
              clickable={agree}
              Icon={IoBagCheckOutline}
              text={"Checkout"}
            />
          </div>
        </div>
        <span className={style.fade_overlay} />
      </div>
    );
  else
    return (
      <MobileCart
        cart={cart}
        agree={agree}
        setAgree={setAgree}
        checkout={checkout}
        amount={amount}
        dispatcher={dispatch}
      />
    );
};

export default Cart;
