import React from "react";
import global_style from "../../../styles/global.module.scss";
import style from "./style.module.scss";

const CancelPage = () => {
  return (
    <div className={[global_style.max_vert_center, style.container].join(" ")}>
      <h1 className={style.title}>Purchase canceled😔</h1>
      <p className={style.text}>
        Please let us know if there was any errors that lead to this.
      </p>
      <p className={style.text}>
        Contact us at adultbricks.help@gmail.com if you have any complaints.
      </p>
    </div>
  );
};

export default CancelPage;
