import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { FaCartShopping } from "react-icons/fa6";
import { useSelector } from "react-redux";

const DesktopHeader = () => {
  const cart = useSelector((state) => state.value);
  const [cartCount, setCartCount] = useState(0);

  function getCartCount() {
    var count = 0;

    cart.forEach((item) => {
      console.log(item);
      count += item.qty;
    });

    setCartCount(count);
  }

  useEffect(() => {
    getCartCount();
  }, [cart]);

  return (
    <>
      <div className={style.header}>
        <ul className={style.header_left}>
          <li>
            <a href="/">
              <h1>AdultBricks</h1>
            </a>
          </li>

          {window.innerWidth > 900 ? (
            <li>
              <a href="/">
                <h3>Home</h3>
              </a>
            </li>
          ) : null}

          <li>
            <a href="/shop">
              <h3>Collections</h3>
            </a>
          </li>

          <li>
            <a href="/about">
              <h3>About</h3>
            </a>
          </li>

          {window.innerWidth > 900 ? (
            <li>
              <a href="/contact">
                <h3>Contact</h3>
              </a>
            </li>
          ) : null}
        </ul>

        <ul className={style.header_right}>
          <li>
            <a href="/cart">
              <FaCartShopping className={style.header_right_icon} />
              <h4>{cartCount}</h4>
            </a>
          </li>
        </ul>
      </div>

      <span className={style.underline} />
    </>
  );
};

export default DesktopHeader;
