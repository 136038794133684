import React from "react";
import style from "./style.module.scss";
import global_style from "../../../styles/global.module.scss";

const NotFound = () => {
  return (
    <div
      style={{ height: "80vh" }}
      className={[
        global_style.max_size,
        global_style.center_children,
        global_style.vertical_list,
      ].join(" ")}
    >
      <h2 className={style.notFound_text}>404</h2>

      <img
        className={style.notFound_img}
        alt="pcb"
        src="https://cdn.pixabay.com/photo/2014/10/23/14/35/electrician-499799_1280.jpg"
      />
      <h2 className={style.notFound_text}>Can't find that page in memory 😔</h2>
    </div>
  );
};

export default NotFound;
