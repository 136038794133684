import React from "react";
import style from "./style.module.scss";

const Legal = () => {
  return (
    <div className={style.legal_container}>
      <h1>Our Terms & Policys</h1>
      <section className={style.legal_container_section}>
        <h2>Terms of Service Agreement</h2>
        <p>
          This Terms of Service Agreement ("Agreement") is a legally binding
          contract between us ("Company," "we," "us," or "our") and you ("User,"
          "you," or "your") governing the use of our website, services, and
          products.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Cookies</h2>
        <p>
          We may use cookies to enhance User experience. By using our website,
          you consent to our use of cookies in accordance with our Privacy
          Policy.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Terms of Payment</h2>
        <p>
          Payment for Products must be made at the time of purchase. We accept
          payment through Stripe. All payments are processed securely through
          Stripe, our third-party payment processor.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Storage Terms</h2>
        <p>
          We will store your personal information in accordance with our Privacy
          Policy. We do not store payment information; it is handled securely by
          Stripe, our payment processor.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Analytics Terms</h2>
        <p>
          We may collect and use data analytics to improve our services and
          marketing efforts. By using our website, you consent to the collection
          and use of your data in accordance with our Privacy Policy.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Shipping Terms</h2>
        <p>
          We will ship Products to the address provided by you at the time of
          purchase. We aim to ship orders promptly, but delivery times may vary
          depending on location and shipping method. We are not responsible for
          delays caused by third-party shipping carriers. In case of a lost
          package contact us.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Services Terms</h2>
        <p>
          Our website and services are provided on an "as is" and "as available"
          basis. We reserve the right to modify, suspend, or discontinue any
          part of our website or services at any time without notice.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Refund Terms</h2>
        <p>
          Goods must be returned in their original condition. You are
          responsible for the return shipping costs unless the return is a
          result of our error (e.g., you received an incorrect or defective
          item). Refunds will be issued to the original payment method used for
          the purchase.
          <br />
          Fees that may arrise from refunding will be invoiced to the customer.
          Such as transactions fees and shipping fees. We do not allow refunds
          of open or used products. See our refund policy to check if your
          eligible for a refund.
        </p>
      </section>
      <section className={style.legal_container_section}>
        <h2>Refund Policy</h2>
        <p>
          We offer refunds on products or services within 14 days of the date of
          recieving. To be eligible for a refund, your item must be unused and
          in the same condition that you received it. It must also be in the
          original packaging.
          <br /> To inititate a refund start by repacke the product in the
          original packages. Use any valid shipping company for buying the
          shippment and add us as the reciever. Our recieving information is,
          Name: Leon Bynander, city: Knivsta, postalcode: 74190, address:
          Björkstavägen 58, email: adultbricks.help@gmail.com. Before sending
          the package and buying the shipment contact our support about the
          refund and wait for a respond.
          <br />
          If you have any questions please contact our customer support team for
          assistance with refunds.
          <br />
          Upon receiving your withdrawal notice, we will process the refund
          without undue delay and no later than 14 days from the day we received
          your package. We will reimburse all payments received from you.
          <br />
          <b>Conditions for Refund:</b> Goods must be returned in their original
          condition. You are responsible for the return shipping costs unless
          the return is a result of our error (e.g., you received an incorrect
          or defective item). Refunds will be issued to the original payment
          method used for the purchase.
          <br />
          <br />
          If applicable, you will be responsible for paying for your own
          shipping costs for returning your item. Shipping costs on refunds are
          non-refundable.
          <br />
          If you haven’t received a refund yet, first check your bank account
          again. Then contact your credit card company, it may take some time
          before your refund is officially posted. If you’ve done all of this
          and you still have not received your refund yet, please contact us at
          adultbricks.help@gmail.com
          <br />
          We reserve the right to modify or update our refund policy at any
          time. Any changes will be effective immediately upon posting the
          revised policy on our website.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>Privacy Policy</h2>
        <p>
          We may collect personal information that you voluntarily provide to us
          when you: Register an account. Make a purchase. Subscribe to our
          newsletter. Contact us via email or through our contact form. This
          personal information may include: Name Email address Mailing address
          Phone number.
          <br />
          Non-Personal Information: We may also collect non-personal information
          about your visit to our website, such as: IP address Browser type
          Operating system Referring website Pages visited Time spent on each
          page
          <br />
          How We Use Your Information: To Provide and Maintain Our Services: We
          use the information we collect to deliver and improve our products and
          services, process transactions, and provide customer support. To
          Personalize Your Experience: We may use your information to
          personalize your experience on our website, such as recommending
          products or services that may be of interest to you. To Send Periodic
          Emails: If you have opted in to receive marketing emails, we may use
          your email address to send you information and updates about our
          products, promotions, and company news. You can unsubscribe from these
          emails at any time.
          <br />
          Information Sharing and Disclosure: Third-Party Service Providers: We
          may share your information with trusted third-party service providers
          who assist us in operating our website, conducting our business, or
          servicing you. Legal Compliance: We may disclose your information if
          required to do so by law or in response to a valid legal request.
          <br />
          Data Security: We implement appropriate technical and organizational
          measures to protect your information against unauthorized access,
          alteration, disclosure, or destruction. Changes to This Privacy
          Policy: We reserve the right to update or change our privacy policy at
          any time. Any changes will be posted on this page, with a revised
          "Last Updated" date.
        </p>
      </section>

      <section className={style.legal_container_section}>
        <h2>General Legal Terms</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of the Swedish jurisdiction. Any dispute arising out of or
          relating to this Agreement shall be subject to the exclusive
          jurisdiction of the courts in Swedish jurisdiction. If any provision
          of this Agreement is found to be invalid or unenforceable, that
          provision shall be enforced to the maximum extent permissible, and the
          remaining provisions of this Agreement shall remain in full force and
          effect. This Agreement constitutes the entire agreement between you
          and us regarding the subject matter herein and supersedes all prior or
          contemporaneous agreements, understandings, and communications,
          whether written or oral.
        </p>
      </section>
    </div>
  );
};

export default Legal;
