import React from "react";
import global_style from "../../../styles/global.module.scss";
import style from "./style.module.scss";

const ConfirmAtionPage = () => {
  return (
    <div className={[global_style.max_vert_center, style.container].join(" ")}>
      <h1 className={style.title}>Thank you🎉</h1>
      <p className={style.text}>
        We will processes your order as soon as possible
      </p>
      <p className={style.text}>Check your email for a receipt</p>
    </div>
  );
};

export default ConfirmAtionPage;
