import React from "react";
import style from "./style.module.scss";
import { BsPinMap } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";

const ContactCard = ({ Icon, text }) => {
  return (
    <div className={style.contact_card}>
      <Icon className={style.contact_card_icon} />
      <h2 className={style.contact_card_text}>{text}</h2>
    </div>
  );
};

const Contact = () => {
  return (
    <div className={style.contact_container}>
      <section className={style.contact_container_top}>
        <h1>Get in touch</h1>

        <div className={style.contact_container_top_icons}>
          <ContactCard
            className={style.contact_container_top_icons_icon}
            Icon={BsPinMap}
            text={
              "We are located in Sweden \n Address: Björkstavägen 58 \n Postalcode: 74190"
            }
          />
          <ContactCard
            className={style.contact_container_top_icons_icon}
            Icon={IoIosMail}
            text={"adultbricks.help@gmail.com"}
          />
        </div>
      </section>

      <section className={style.contact_container_bottom}>
        <h1>FAQ</h1>
        <ul className={style.contact_container_bottom_faq}>
          <li>
            <h2>How do I track my order?</h2>
            <p>
              When your order is shipped you will get an email notifying you. In
              the email shipment info is given, including the tracking
              information.
            </p>
          </li>

          <li>
            <h2>Wheres my order?</h2>
            <p>
              If you made a order and got to an confirmation-page on our
              website, the order was succesful. If you however did not get a
              confirmation email a error might have occured. Check if your
              bank-account have been invoiced from us. If you have, please email
              us as fast as possible. In the email write your email used for the
              purchase and the product you bought. We will then help you right
              away from there.
            </p>
          </li>

          <li>
            <h2>I'm not satisfied, how do I return?</h2>
            <p>
              If your not happy with the product based on damages and production
              faults, please begin by emailing us that you want an refund. Write
              the cause and send pictures of the damages, then we will help you
              with the return address and refund the money. Note we dont offer
              shipment for refunds.
            </p>
          </li>

          <li>
            <h2>I'm missing a part</h2>
            <p>
              In the event that a part is missing, start by searching for it,
              if not found please email us the parts needed and we will send you
              new ones.
            </p>
          </li>

          <li>
            <h2>How often should I receive updates?</h2>
            <p>
              When you first make a purchase you will get an confirmation email.
              After the order is packed and ready to ship you will get a
              shipping email containing information about the shipment. The last
              email you will get is a received confirmation email.
            </p>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Contact;
