import React, { useEffect, useLayoutEffect, useState } from "react";
import style from "./style.module.scss";
import global_style from "../../../styles/global.module.scss";
import { FaCartPlus } from "react-icons/fa6";
import ProductCard from "../../product-card/ProductCard";
import StartRating from "../../start-rating/StartRating";
import { getFeatured, getProduct } from "../../../firebase/store_functions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addProduct } from "../../../redux/cartSlice";
import Button from "../../button/Button";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { AnimatePresence, motion, wrap } from "framer-motion";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const ImageGallery = ({ images }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          src={images[imageIndex].big}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.1 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <div onClick={() => paginate(-1)} className={style.img_picker_left}>
        <FaAngleLeft className={style.img_picker_left_icon} />
      </div>

      <div onClick={() => paginate(1)} className={style.img_picker_right}>
        <FaAngleRight className={style.img_picker_right_icon} />
      </div>
    </>
  );
};

const ProductView = () => {
  const [imgs, setImgs] = useState([
    { small: "", big: "" },
    { small: "", big: "" },
    { small: "", big: "" },
    { small: "", big: "" },
  ]);

  const [img_animation, setImg_animation] = useState({
    img1: { show: true, src: imgs[0] },
    img2: { show: false, src: imgs[1] },
  });

  const setImg = (index) => {
    if (img_animation.img1.show) {
      setImg_animation({
        img1: { show: false, src: img_animation.img1.src },
        img2: { show: true, src: imgs[index] },
      });
    } else {
      setImg_animation({
        img1: { show: true, src: imgs[index] },
        img2: { show: false, src: img_animation.img2.src },
      });
    }
  };

  const { id } = useParams();
  const [product, setProduct] = useState();
  const [featured_product, setFeatured_product] = useState([]);
  const [qty, setQty] = useState(1);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    getProduct(id, setProduct);
    getFeatured(setFeatured_product, featured_product, 3);
  }, []);

  useEffect(() => {
    console.log(id);
    if (product !== undefined) {
      var list = [];

      product.smallImgs.forEach((img, i) => {
        list.push({ small: img, big: product.bigImgs[i] });
      });
      setImgs(list);

      setImg_animation({
        img1: { show: true, src: list[0] },
        img2: { show: false, src: list[1] },
      });
    }
  }, [product]);

  useLayoutEffect(() => {
    const update = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);

  const addToCart = () => {
    if (!product.comingSoon)
      dispatch(addProduct({ product: product, qty: qty }));
  };

  const changeQty = (event) => {
    var value = parseInt(
      event.target.value.replace(/[^0-9]/g, "").replace(/^0+(?=\d)/, "")
    );

    if (value < 1) value = 1;

    setQty(value);
  };

  return (
    <div
      className={[
        global_style.max_size,
        global_style.center_children,
        global_style.vertical_list,
      ].join(" ")}
    >
      <div className={style.product_container}>
        {/* images section*/}
        <div className={style.product_container_img}>
          <div
            style={{ backgroundColor: product ? product.color : "gray" }}
            className={style.product_container_img_big_img}
          >
            {width < 901 ? (
              <>
                <ImageGallery images={imgs} />
              </>
            ) : (
              <>
                <img
                  style={{
                    opacity: img_animation.img1.show ? 1 : 0,
                  }}
                  alt=""
                  src={img_animation.img1.src.big}
                />
                <img
                  style={{
                    opacity: img_animation.img2.show ? 1 : 0,
                  }}
                  className={style.product_container_img_big_img_overlay}
                  alt=""
                  src={img_animation.img2.src.big}
                />
              </>
            )}
          </div>

          <div className={style.product_container_img_small_imgs}>
            <img
              style={{ backgroundColor: product ? product.color : "gray" }}
              onClick={() => setImg(0)}
              alt=""
              src={imgs[0].small}
            />
            <img
              style={{ backgroundColor: product ? product.color : "gray" }}
              onClick={() => setImg(1)}
              alt=""
              src={imgs[1].small}
            />
            <img
              style={{ backgroundColor: product ? product.color : "gray" }}
              onClick={() => setImg(2)}
              alt=""
              src={imgs[2].small}
            />
            <img
              style={{ backgroundColor: product ? product.color : "gray" }}
              onClick={() => setImg(3)}
              alt=""
              src={imgs[3].small}
            />
          </div>
        </div>

        {/* product about section*/}
        {product !== undefined ? (
          <div className={style.product_container_description_container}>
            <h6>{product.subTitle}</h6>
            <h1>{product.name}</h1>
            <StartRating
              rating={product.reviewSum.toFixed(0)}
              reviews={product.reviews}
            />
            <p>{product.description}</p>

            <div
              className={style.product_container_description_container_price}
            >
              <h2>{(product.price / 100).toFixed(2)}€</h2>

              <div className={global_style.underline_text}>-25%</div>

              <h3>{(((product.price / 75) * 100) / 100).toFixed(2)}€</h3>
            </div>

            <div
              className={style.product_container_description_container_purchase}
            >
              <div
                className={
                  style.product_container_description_container_purchase_amount
                }
              >
                <button
                  onClick={() => {
                    setQty(qty - 1 > 0 ? qty - 1 : 1);
                  }}
                >
                  -
                </button>
                <input value={qty} onChange={(e) => changeQty(e)} />
                <button
                  onClick={() => {
                    setQty(qty + 1);
                  }}
                >
                  +
                </button>
              </div>

              <div
                className={
                  style.product_container_description_container_purchase_buy
                }
              >
                <Button
                  onClick={addToCart}
                  clickable={true}
                  Icon={
                    product.comingSoon
                      ? () => {
                          <></>;
                        }
                      : FaCartPlus
                  }
                  text={product.comingSoon ? "Coming soon" : "Add to cart"}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <h1 style={{ width: "90%" }} className={global_style.section_title}>
        Recommended
      </h1>
      <div className={style.recommended_container}>
        <div className={style.recommended_container_list}>
          {featured_product.map((v, i) => (
            <ProductCard
              product={v}
              delay={(i / featured_product.length) * 0.25}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductView;
