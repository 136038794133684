import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "./Firebase";

export const getAllProducts = async (setState, state) => {
  const req = collection(db, "products");
  const snap = await getDocs(req);

  var docs = [];

  snap.forEach((doc) => {
    docs.push({ ...doc.data(), id: doc.id });
  });
  setState(docs);
};

export const getFeatured = async (setState, state,maxCount) => {
  const req = collection(db, "features");
  var docs = [];

  const snap = await getDocs(req);
  var count = 0;

  snap.forEach(async (d) => {
    if(count++ >= maxCount)
      return

    await getDoc(doc(db, "products", d.data().id)).then((e) => {
      docs.push({ ...e.data(), id: e.id });
      setState([...docs])
    });
  });

  
};

export const getProduct = (id, setState) => {
  const req = doc(db, "products", id);

  getDoc(req).then((res) => {
    setState({ ...res.data(), id: res.id });
  });
};
