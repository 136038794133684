import React from "react";
import "./style.module.scss";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <Header />

      {children}

      <Footer />
    </div>
  );
};

export default MainLayout;
