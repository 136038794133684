// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkQrDmnG3U9aBPhR4aiJKw-JdbmYr5Kz0",
  authDomain: "adultbricks-49d1b.firebaseapp.com",
  projectId: "adultbricks-49d1b",
  storageBucket: "adultbricks-49d1b.appspot.com",
  messagingSenderId: "122041127735",
  appId: "1:122041127735:web:9a0b903ba799fb4d1380b2",
  measurementId: "G-EKVETTF01N"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const functions = getFunctions(app)
